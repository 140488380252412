import { getSessionItem, KEYS } from '../store/storage';
import moment from 'moment';
import {
  getLoyaltySettings,
  updateLoyaltySettings,
  getPrepaidCardContract,
  getPrepaidCardPurchaseOptions,
  toggleActivePrepaidCard,
  removePrepaidCardPurchaseOption,
  savePrepaidCardPurchaseOption,
  updatePrepaidCardPurchaseOption,
} from '../services/loyalty';
import { LOCALE_EN } from '../consts';

const BASE = 'LOYALTY_SETTINGS';

export const actions = {
  updateSettings: `${BASE}_UPDATE_SETTINGS`,
  updatePrepaidCardSettings: `${BASE}_UPDATE_PREPAIDCARD_SETTINGS`,
};

export const loadRestaurantLoyaltySettings = () => async (dispatch, getState) => {
  const { loyaltySettings } = getState().loyaltySettings;
  if (loyaltySettings) return;

  const locale = getSessionItem(KEYS.locale) || LOCALE_EN;
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await getLoyaltySettings({ locale, restaurant_id });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;
  dispatch({
    type: actions.updateSettings,
    payload: response.data,
  });
};

export const updateRestaurantLoyaltySettings = (payload, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale) || LOCALE_EN;
  let response;
  try {
    response = await updateLoyaltySettings({ locale, ...payload });
  } catch (e) {
    response = { success: false };
  }
  callback && callback(response);
  if (!response.success) return;
  const { loyaltySettings } = getState().loyaltySettings;
  const newSettings = {
    ...loyaltySettings,
    ...payload,
  };
  dispatch({
    type: actions.updateSettings,
    payload: newSettings,
  });
};

export const loadRestaurantLoyaltyPrePaidCardSettings = () => async (dispatch, getState) => {
  const { prepaidCardSettings } = getState().loyaltySettings;
  if (prepaidCardSettings) return;

  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;

  try {
    response = await getPrepaidCardContract({ locale, restaurant_id });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success || !response.data) {
    dispatch({
      type: actions.updatePrepaidCardSettings,
      payload: {
        prepaidCardSettings: undefined,
        prepaidCardSettingsHasLoaded: true,
      },
    });
    return;
  }

  const prepaidCardSettingsData = { ...response.data };
  prepaidCardSettingsData.purchaseOptions = [];

  try {
    response = await getPrepaidCardPurchaseOptions({
      locale,
      restaurant_id,
      prepaidCardDealContractId: prepaidCardSettingsData.id,
    });
  } catch (e) {
    response = { success: false };
  }

  if (response.success) {
    prepaidCardSettingsData.purchaseOptions = (response.data || [])
      .map((item) => {
        return {
          ...item,
          purchaseOptionId: item.id,
          purchaseAmount: Number.parseInt(item.cash_amount),
          bonusAmount: Number.parseInt(item.bonus_amount),
          prepaidCardDealContractId: item.deal_contract_id,
        };
      })
      .sort((itemA, itemB) => {
        return moment(itemA.created_at).valueOf() - moment(itemB.created_at).valueOf();
      });
  }

  dispatch({
    type: actions.updatePrepaidCardSettings,
    payload: {
      prepaidCardSettings: prepaidCardSettingsData,
      prepaidCardSettingsHasLoaded: true,
    },
  });
};

export const toggleActiveRestaurantLoyaltyPrepaidCard = (payload, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const { prepaidCardDealContractId, actived, autoSelectedMemberJoinMode } = payload;
  let response;
  try {
    response = await toggleActivePrepaidCard({ locale, actived, prepaidCardDealContractId });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;
  const { loyaltySettings } = getState().loyaltySettings;
  let newLoyaltySettings = { ...loyaltySettings };
  if (autoSelectedMemberJoinMode) {
    newLoyaltySettings = {
      ...loyaltySettings,
      join_member_mode: autoSelectedMemberJoinMode,
    };
    let response;
    try {
      response = await updateLoyaltySettings({ locale, ...newLoyaltySettings });
    } catch (e) {
      response = { success: false };
    }
  }

  if (!response.success) return;

  const { prepaidCardSettings } = getState().loyaltySettings;
  const newSettings = {
    ...prepaidCardSettings,
    is_active: actived,
  };
  dispatch({
    type: actions.updatePrepaidCardSettings,
    payload: {
      prepaidCardSettings: newSettings,
      prepaidCardSettingsHasLoaded: true,
      loyaltySettings: newLoyaltySettings,
    },
  });
  typeof callback === 'function' && callback(response);
};

export const removeRestaurantLoyaltyPrepaidCardPurchaseOption = (payload, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const { purchaseOptionId, prepaidCardDealContractId } = payload;
  let response;

  try {
    response = await removePrepaidCardPurchaseOption({ locale, purchaseOptionId, prepaidCardDealContractId });
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;

  const { prepaidCardSettings } = getState().loyaltySettings;
  const purchaseOptions = prepaidCardSettings.purchaseOptions.filter((itme) => {
    if (itme.purchaseOptionId === purchaseOptionId) {
      return false;
    }
    return true;
  });
  const newSettings = {
    ...prepaidCardSettings,
    purchaseOptions,
  };
  dispatch({
    type: actions.updatePrepaidCardSettings,
    payload: {
      prepaidCardSettings: newSettings,
    },
  });
};

export const saveOrUpdatePurchseOption = (payload, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const { purchaseAmount, bonusAmount, prepaidCardDealContractId, purchaseOptionId, doUpdate } = payload;
  const { prepaidCardSettings } = getState().loyaltySettings;
  const index = prepaidCardSettings.purchaseOptions.findIndex((purchaseOption) => {
    if (purchaseOption.purchaseOptionId === purchaseOptionId) {
      return true;
    }
    return false;
  });
  let response;
  let refreshedPurchaseOption = null;
  try {
    if (doUpdate) {
      // check should do update or not
      const prevPurchaseOption = prepaidCardSettings.purchaseOptions[index];
      if (prevPurchaseOption.purchaseAmount === purchaseAmount && prevPurchaseOption.bonusAmount === bonusAmount) {
        return;
      }
      response = await updatePrepaidCardPurchaseOption({
        locale,
        purchaseAmount,
        bonusAmount,
        prepaidCardDealContractId,
        purchaseOptionId,
      });
      refreshedPurchaseOption = response.data;
    } else {
      response = await savePrepaidCardPurchaseOption({
        locale,
        purchaseAmount,
        bonusAmount,
        prepaidCardDealContractId,
      });
      refreshedPurchaseOption = response.data;
    }
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback({ success: response.success, doUpdate });

  if (!response.success || (!doUpdate && !refreshedPurchaseOption)) return;

  const copyied = [...prepaidCardSettings.purchaseOptions];
  // mock data
  if (doUpdate) {
    copyied.splice(index, 1, {
      ...refreshedPurchaseOption,
      purchaseAmount,
      bonusAmount,
      purchaseOptionId,
      prepaidCardDealContractId,
    });
  } else {
    copyied.push({
      ...refreshedPurchaseOption,
      purchaseAmount,
      bonusAmount,
      purchaseOptionId: refreshedPurchaseOption.id,
      prepaidCardDealContractId,
    });
  }

  copyied.sort((itemA, itemB) => {
    return moment(itemA.created_at).toDate().getTime() - moment(itemB.created_at).toDate().getTime();
  });

  const newSettings = {
    ...prepaidCardSettings,
    purchaseOptions: copyied,
  };
  dispatch({
    type: actions.updatePrepaidCardSettings,
    payload: {
      prepaidCardSettings: newSettings,
    },
  });
};
